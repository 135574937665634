@use '../../variables.scss' as v;

.textarea {
  outline: 1px solid lightgray;
  border-radius: 2px;
  display: block;
  padding: 16px;
  background-color: v.$color-white;
  width: 100%;
  border: none;
  resize: vertical;
  min-height: 150px;
  font-size: 13px;
  transition:
    background-color 0.2s ease,
    box-shadow 0.2s ease;
}

.textarea:hover {
  background-color: v.$bg-input;
}

.textarea:focus {
  box-shadow: 0 10px 20px 0 v.$shadow;
  background-color: v.$bg-input;
}

.label {
  color: v.$text;
  cursor: default;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 30px;
}
