@use '../../variables.scss' as v;

.number {
  display: flex;
  align-items: center;
}

.number__icon {
  height: 25px;
}

.number__phone {
  font-family: Oswald;
  font-size: 25px;
  color: v.$secondary;
  transition: color 0.1s ease;
  font-weight: 400;
}

.number a {
  display: flex;
  align-items: center;
}

.number a svg {
  margin-right: 8px;
  transition: fill 0.1s ease;
}

.number__phone:hover {
  color: v.$accent;
}
