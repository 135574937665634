@use '../../variables.scss' as v;

.assortment-card {
  margin-top: 75px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 25px 50px 25px;
  background-color: v.$color-white;
  width: 230px;
  min-width: auto;
  margin-right: 30px;
  border-radius: 6px;
  transition: all 0.2s ease;
  user-select: none;
}

.assortment-card__title {
  color: v.$title;
  font-family: Oswald;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 30px;
  text-decoration: none;
  text-align: center;
  text-transform: none;
  margin-bottom: 15px;
  margin-top: 25px;
  transition: color 0.2s ease;
}

.assortment-card__description {
  color: v.$text;
  font-size: 17px;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  line-height: 27px;
  text-decoration: none;
  text-transform: none;
  margin-bottom: 0px;
  margin-top: 0px;
  transition: color 0.2s ease;
}

.assortment-card .assortment-card__image svg {
  transition: fill 0.2s ease;
}

.assortment-card:hover {
  background-color: v.$secondary;
  color: white;
}

.assortment-card:hover .assortment-card__title {
  color: white;
}

.assortment-card:hover .assortment-card__description {
  color: white;
}

.assortment-card:hover .assortment-card__image svg {
  fill: white;
}
