@use '../../variables.scss' as v;

.about-company__top-block:not(.animated),
.about-company__bottom-block-cards:not(.animated) {
  opacity: 0;
}

.about-company {
  width: 100%;
  overflow-x: visible;
  background-color: white;
}

.about-company__wrapper {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
}

.about-company__top-block {
  display: flex;
}

.about-company__description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 600px;
  margin-top: 75px;
  margin-bottom: 25px;
}

.about-company__title {
  color: v.$text;
  font-family: Oswald;
  font-size: 40px;
  font-style: normal;
  letter-spacing: 0.5px;
  line-height: 50px;
  text-align: start;
  text-decoration: none;
  text-transform: none;
  font-weight: normal;
  margin-bottom: 25px;
}

.about-company__about {
  word-break: normal;
  color: v.$text;
  font-size: 19px;
  font-style: normal;
  letter-spacing: 0px;
  line-height: 31px;
  text-align: left;
  text-decoration: none;
  text-transform: none;
}

.about-company__expanded-block {
  display: flex;
  width: 100%;
  padding: 50px 35px;
  border: 7px solid v.$primary;
  margin: -50px 0;
  margin-left: 24px;
}

.about-company__bottom-block {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
}

.about-company__bottom-block-image {
  background-size: cover;
  background-position: center;
  background-attachment: scroll;
  position: absolute;
  height: 180%;
  width: 100%;
  z-index: -1;
}

.about-company__bottom-block-cards {
  display: flex;
  width: 100%;
  max-width: 1170px;
  justify-content: space-between;
  margin: 0 16px;
}

.about-company__bottom-block-card {
  width: 23%;
}

@media (max-width: 1210px) {
  .about-company__wrapper {
    padding: 0 16px;
  }

  .about-company__top-block {
    flex-direction: column;
    width: 100%;
  }

  .about-company__title {
    text-align: center;
  }

  .about-company__description {
    width: 100%;
    max-width: none;
  }

  .about-company__expanded-block {
    padding: 0 30px;
    margin: 0;
    margin-bottom: 32px;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .about-company__card {
    width: 48%;
    margin: 0;
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

@media (max-width: 1033px) {
  .about-company__bottom-block {
    height: 514px;
  }

  .about-company__bottom-block-cards {
    flex-wrap: wrap;
    gap: 20px;
  }

  .about-company__bottom-block-card {
    width: 48%;
  }
}

@media (max-width: 550px) {
  .about-company__title {
    font-size: 24px;
    line-height: 32px;
  }

  .about-company__about {
    font-size: 17px;
    line-height: 29px;
  }

  .about-company__expanded-block {
    flex-direction: column;
  }

  .about-company__card {
    width: 100%;
  }

  .about-company__bottom-block {
    height: 731px;
  }

  .about-company__bottom-block-cards {
    flex-direction: column;
    gap: 20px;
  }
  .about-company__bottom-block-card {
    width: 100%;
  }
}
