@use '../../variables.scss' as v;

.assortment-porternie-list__item {
  width: calc((100% / 4) - 18px);
  height: 250px;
  position: relative;
}

.assortment-porternie-list__item a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-item__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: -1;
  border-radius: 4px;
}

.list-item__card {
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  padding: 16px;
  padding-bottom: 24px;
  border-radius: 4px;
  position: relative;
}

.list-item__card::after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  height: 6px;
  width: 100%;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: v.$primary;
}

.list-item__image-container {
  display: flex;
  height: 60px;
  width: 100%;
  align-items: center;
}

.list-item__title {
  font-size: 17px;
  color: v.$text;
  font-weight: normal;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 16px;
}

.list-item__card img {
  width: auto;
  vertical-align: middle;
}

@media (max-width: 1205px) {
  .assortment-porternie-list__item {
    width: calc((100% / 3) - 16px);
  }
}

@media (max-width: 888px) {
  .assortment-porternie-list__item {
    width: calc((100% / 2) - 12px);
  }
}

@media (max-width: 620px) {
  .assortment-porternie-list__item {
    width: 100%;
  }
}
