.close-button {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: max-content;
  padding: 10px;
  transition: all 0.2s ease;
}

.close-button:hover {
  transform: scale(1.5);
}

.close-button__content {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: transparent;
  position: relative;
}

.close-button__content::before,
.close-button__content::after {
  position: absolute;
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: black;
}

.close-button__content::before {
  transform: rotate(45deg);
}

.close-button__content::after {
  transform: rotate(-45deg);
}
