@use '../../variables.scss' as v;

.base-button {
  cursor: pointer;
  color: white;
  background-color: v.$primary;
  padding: 20px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  box-shadow: 0 5px 20px 0 rgba(v.$shadow, 0.2);
  transition: all 0.1s ease;
  width: max-content;
}

.base-button:disabled {
  color: v.$button-color-disabled;
  background-color: v.$button-bg-disabled;
}
