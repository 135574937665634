@use '../../variables.scss' as v;

.input {
  outline: 1px solid lightgray;
  display: block;
  padding: 16px;
  background-color: v.$color-white;
  width: 100%;
  border-radius: 2px;
}

.label {
  color: v.$text;
  cursor: default;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 30px;
}
