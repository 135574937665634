@use '../../variables.scss' as v;

.services-item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  background-color: v.$color-white;
  padding-bottom: 50px;
  box-shadow: 0 10px 20px 0 v.$shadow;
  border-radius: 4px;
}

.services-item__name {
  color: v.$title;
  font-family: 'Oswald';
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 30px;
  text-decoration: none;
  text-transform: none;
  text-align: center;
  font-weight: 600;
  transition: color 0.2s ease;
}

.services-item__image {
  width: 100%;
  height: 250px;
  overflow: hidden;
}

.services-item__image a {
  width: 100%;
  height: 250px;
  background-size: cover;
}

.services-item__image a div {
  height: 100%;
  background-position: center;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  transition: scale 0.2s ease;
}

.services-item:hover .services-item__image a div {
  scale: 1.2;
}

.services-item__button {
  transition:
    background 0.2s ease,
    box-shadow 0.2s ease;
}

.services-item__button:hover {
  transform: none;
  background-color: v.$color-pink;
  box-shadow: inherit;
}

.services-item__name:hover {
  color: v.$primary;
}
