@use '../../variables.scss' as v;

.modal-background {
  background-color: v.$overlay;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

.modal-background:not(.modal-background.removing) {
  animation: smooth 0.2s linear;
}

.modal-background.removing {
  animation: smooth-reverse 0.2s linear;
  opacity: 0;
}

.modal {
  min-width: 320px;
  width: 80%;
  max-width: 1170px;
  max-height: 90%;
  overflow-y: auto;
  scroll-padding: 2px;
  scroll-margin: 2px;
  border-radius: 5px;
}

.modal::-webkit-scrollbar-thumb:hover {
  background-color: gray;
}

.modal::-webkit-scrollbar {
  width: 16px;
}

.modal::-webkit-scrollbar-track {
  background-color: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.modal::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 16px;
  border: 6px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  transition: all 1s ease;
}

.modal-content {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  position: relative;
}

.modal-content-h1 {
  text-align: center;
  color: v.$primary;
  font-family: 'Oswald';
  font-size: 24px;
  font-style: normal;
  letter-spacing: 0px;
  line-height: 34px;
  text-decoration: none;
  text-transform: none;
  font-weight: normal;
}

.modal-content-h2 {
  text-align: center;
  color: v.$title;
  font-weight: 700;
  font-size: 17px;
  font-style: normal;
  letter-spacing: 0px;
  line-height: 31px;
}

.order.modal-content__order {
  display: block;
  margin-bottom: 0;
}

.order.modal-content__order .order__button-wrapper {
  margin-bottom: 0;
}

.order.modal-content__order .order__wrapper {
  max-width: none;
}

form.modal-content__order--form {
  width: 100%;
  padding: 0;
  margin: 0;
}

.modal-content__order-textarea {
  max-height: 320px;
}

@keyframes smooth {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes smooth-reverse {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
