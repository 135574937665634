@use '../../variables.scss' as v;

.order__image-wrapper:not(.animated) {
  opacity: 0;
}

.order {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 25px 0;
}

.order__wrapper {
  max-width: 1170px;
  display: flex;
  align-items: center;
  margin: 0 16px;
  height: 100%;
}

.order__main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.order__image-wrapper {
  height: 100%;
}

.order__image {
  max-height: 100%;
  height: auto;
  width: 100%;
  padding: 20px;
}

.order__form {
  width: 100%;
  margin-left: 32px;
  padding-left: 50px;
  padding-top: 50px;
}

.order__title {
  color: v.$title;
  font-family: 'Oswald';
  font-size: 40px;
  font-style: normal;
  letter-spacing: 0.5px;
  line-height: 50px;
  text-align: start;
  text-decoration: none;
  text-transform: none;
  font-weight: inherit;
  margin: 25px 0;
}

.order__input {
  border: none;
  outline: 1px solid lightgray;
  transition: all 0.2s ease;
}

.order__input:hover {
  background-color: v.$bg-input;
}

.order__input:focus {
  box-shadow: 0 10px 20px 0 v.$shadow;
  background-color: v.$bg-input;
}

.order__description {
  margin-bottom: 15px;
  color: v.$text;
  font-size: 19px;
  font-style: normal;
  letter-spacing: 0px;
  line-height: 31px;
  text-align: left;
  text-decoration: none;
  text-transform: none;
}

.order__categories-title {
  color: v.$title;
  margin-bottom: 8px;
  font-size: 15px;
}

.order__categories {
  display: flex;
  flex-wrap: wrap;
}

.order__categories > div {
  width: calc(100% / 4);
  border-left-color: transparent;
}

.order__categories > div:last-child,
.order__categories > div:nth-last-child(2),
.order__categories > div:nth-last-child(3) {
  border-top-color: transparent;
}

.order__categories > div:nth-last-child(3),
.order__categories > div:first-child {
  border-left-color: v.$border;
}

.order__categories > div .order__input {
  margin-bottom: 16px;
}

.order__button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 48px;
}

.order__button {
  overflow: hidden;
  padding: 11px;
}

.order__button-text {
  display: flex;
  width: 200%;
  align-items: center;
  justify-content: space-between;
  transform: translateX(-57%);
  transition: transform 0.2s ease;
}

.order__button-text > span {
  text-align: center;
}

.order__button:not(:disabled):hover {
  transform: translate(0);
}

.order__button:not(:disabled):hover .order__button-text {
  transform: translateX(19%);
}

@media (max-width: 770px) {
  .order__main {
    flex-direction: column;
  }

  .order__image-wrapper {
    align-self: center;
  }

  .order__title {
    text-align: center;
    font-size: 28px;
    margin-top: 0;
    margin-bottom: 16px;
  }

  .order__input-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 96px;
  }

  .order__image {
    width: 100%;
    max-width: 600px;
  }

  .order__form {
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 350px) {
  .order__input-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 48px;
  }
}
