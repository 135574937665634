@use '../../variables.scss' as v;

.parallax-bg {
  width: 100%;
}

.parallax-bg__container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: max-content;
}

.parallax-bg__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: v.$overlay;
  z-index: -1;
}

.parallax-bg__wrapper {
  min-height: 50px;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -2;
  overflow: hidden;
}

.parallax-bg__image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  height: 180%;
  position: absolute;
  width: 100%;
}

@media (max-width: 550px) {
  .parallax-bg__container {
    padding: 32px 0;
  }
}
