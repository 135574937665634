@use '../../variables.scss' as v;

.our-location {
  width: 100%;
  display: flex;
  height: 400px;
  overflow: hidden;
}

.our-location__studio {
  width: 33.7%;
  border: 6px solid v.$primary;
  padding: 50px;
  background-color: v.$color-white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.our-location__title {
  color: v.$text;
  font-family: 'Oswald';
  font-size: 24px;
  font-style: normal;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 32px;
  font-weight: normal;
}

.our-location__address {
  color: v.$text;
  font-size: 19px;
  font-style: normal;
  letter-spacing: 0px;
  line-height: 31px;
  text-align: left;
  text-decoration: none;
  text-transform: none;
  word-break: normal;
}

.our-location__instagram {
  margin-left: -8px;
  margin-top: 50px;
}

.our-location__instagram a svg,
.our-location__instagram a p {
  fill: v.$primary !important;
  color: v.$primary !important;
  font-size: 14px;
}

.our-location__instagram:hover a svg,
.our-location__instagram:hover a p {
  fill: v.$color-pink !important;
  color: v.$color-pink !important;
}

.our-location .map {
  width: 66.7%;
  border: none;
}

@media (max-width: 990px) {
  .our-location__studio {
    width: 50%;
  }
}

@media (max-width: 730px) {
  .our-location {
    flex-direction: column;
    height: max-content;
  }

  .our-location__studio {
    width: 100%;
    align-items: center;
  }

  .our-location__title {
    margin-top: 0;
  }

  .our-location .map {
    width: 100%;
    height: 320px;
  }
}
