$primary: rgba(253, 7, 178, 1);
$secondary: rgba(19, 162, 197, 1);
$accent: rgba(8, 189, 232, 1);
$title: rgba(66, 66, 66, 1);
$subtitle: rgba(29, 29, 31, 0.4);
$text: rgba(66, 66, 66, 1);
$icon: rgba(66, 66, 66, 1);
$title-inverse: #ffffff;
$text-inverse: rgba(255, 255, 255, 0.4);
$bg-primary: #ffffff;
$bg-secondary: #f5f8f8;
$bg-dark: #1e293d;
$bg-dark-accent: #20364c;
$bg-input: rgba(247, 237, 241, 1);
$border: #eeeeee;
$shadow: rgba(0, 0, 0, 0.15);
$overlay: rgba(0, 0, 0, 0.5);
$hover: #2f3439;
$color-white: rgba(255, 249, 251, 1);
$color-pink: rgba(235, 87, 183, 1);
$color-blue: rgba(37, 185, 222, 1);
$color-red: #ff4f49;
$color-orange: #ff7a2f;
$color-yellow: #ffc700;
$color-lime: #34dca2;
$color-aqua-blue: #00ada9;
$button-bg-disabled: rgb(165 6 116);
$button-color-disabled: #c1c1c1;

$component-animation: smooth-render 1s ease;
