@use '../../variables.scss' as v;

.burger__button {
  cursor: pointer;
  display: flex;
  height: 40px;
  background-color: transparent;
  align-items: center;
  text-align: end;
  margin-left: auto;
}

.burger__button span {
  color: v.$text;
  text-transform: lowercase;
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
  margin-bottom: 2px;
  text-align: center;
  transition: color 0.2s ease;
}

.burger__button svg path {
  stroke: v.$text;
  transition:
    transform 0.3s ease,
    stroke 0.2s ease;
}

.burger__button:hover svg path:nth-child(1) {
  transform: rotate(30deg) translateY(-9px) translateX(6px) scaleX(0.7);
}

.burger__button:hover svg path:nth-child(3) {
  transform: rotate(-30deg) translateY(6px) translateX(-6px) scaleX(0.7);
}

.burger__button svg {
  transition: transform 0.2s ease;
}

.burger__button:hover svg {
  transform: rotate(180deg);
}
.burger__button:hover svg path {
  stroke: v.$accent;
}

.burger__button:hover span {
  color: v.$accent;
}
