@use '../../variables.scss' as v;

.error {
  width: 100%;
  padding: 0 16px;
  height: 80vh;
  display: flex;
  align-items: center;
  position: relative;
}

.error__wrapper {
  width: 100%;
  max-width: 1170px;
  margin: auto;
}

.error__content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.error__background {
  background-color: lightblue;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -2;
}

.error__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: v.$overlay;
}

.error__title {
  font-weight: 700;
  font-size: 156px;
  line-height: 156px;
  letter-spacing: 0px;
  color: white;
  text-decoration: none;
  text-transform: none;
  font-style: normal;
  text-align: center;
}

.error__description {
  font-weight: 700;
  color: white;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  letter-spacing: 0px;
  line-height: 31px;
  text-decoration: none;
  text-transform: none;
}

.error__title,
.error__description {
  margin: 0;
  text-shadow: 0 0 15px 0 v.$shadow;
  margin-bottom: 25px;
}

.error__button {
  background-color: v.$secondary;
  transition: box-shadow 0.2s ease;
}

.error__button:hover {
  box-shadow: 0 0 20px rgba(v.$secondary, 1);
}
