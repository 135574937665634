@use '../../variables.scss' as v;

.invitation__description:not(.animated),
.invitation__block:not(.animated) {
  opacity: 0;
}

.invitation {
  width: 100%;
  padding: 50px 0;
}

.invitation__wrapper {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
}

.invitation__description {
  color: v.$text;
  font-family: 'Oswald';
  font-size: 24px;
  font-style: normal;
  letter-spacing: 0px;
  line-height: 44px;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  font-weight: normal;
  margin-bottom: 75px;
}

.invitation__blocks-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 24px;
  // overflow: hidden;
}

.invitation__block {
  border: 4px solid v.$primary;
  border-radius: 4px;
  padding: 24px;
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 16px;
}

.block__description {
  color: v.$text;
  font-size: 19px;
  font-style: normal;
  letter-spacing: 0px;
  line-height: 31px;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  font-weight: normal;
}

.block__number {
  color: v.$text;
}

.block__button {
  transition:
    box-shadow 0.2s ease,
    background-color 0.2s ease;
}

.block__button:hover {
  box-shadow: 0 0 40px -5px rgba(v.$primary, 0.6);
  background-color: v.$color-pink;
}

.block__number:hover {
  color: v.$primary;
}

@media (max-width: 600px) {
  .invitation__blocks-wrapper {
    flex-direction: column;
  }

  .invitation__block {
    width: 100%;
  }

  .block__button {
    width: 100%;
  }
}
