@use '../../variables.scss' as v;

.mail {
  color: white;
  transition: color 0.1s ease;
  position: relative;
  padding-left: 28px;
}

.mail svg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 24px;
  height: 24px;
  max-height: 100%;
}

.mail:hover {
  color: v.$accent;
}

.mail svg g g {
  transition: fill 0.1s ease;
}

.mail:hover svg g g {
  fill: v.$accent;
}
