@use '../../variables.scss' as v;

.our-projects__title:not(.animated),
.our-projects__content:not(.animated) {
  opacity: 0;
}

.our-projects {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.our-projects__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
}

.our-projects__title {
  color: v.$title;
  font-family: 'Oswald';
  font-size: 40px;
  font-style: normal;
  letter-spacing: 0.5px;
  line-height: 50px;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  font-weight: 500;
  margin-bottom: 50px;
}

.our-projects__content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 48px;
  padding: 0;
}

.our-projects__button {
  margin-top: 48px;
  background-color: v.$secondary;
}

.our-projects__item {
  width: 33.3%;
  overflow: hidden;
}

.our-projects__item img {
  width: 100%;
  transition: scale 0.2s ease;
}

.our-projects__item:hover img {
  scale: 1.2;
  cursor: zoom-in;
}

.our-projects__button:hover {
  box-shadow: 0 0 30px 0 rgba(v.$secondary, 0.8);
}

@media (max-width: 770px) {
  .our-projects__wrapper .photo-gallery__photo {
    width: 50% !important;
  }
}

@media (max-width: 420px) {
  .our-projects__wrapper .photo-gallery__photo {
    width: 100% !important;
  }
}
