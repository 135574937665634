@use '../../variables.scss' as v;

.container {
  width: 100%;
  padding: 25px 16px;
  position: relative;
}

.wrapper {
  max-width: 1170px;
  margin: 0 auto;
}

.wrapper .services-cards__title {
  margin-bottom: 48px;
}

.wrapper .invitation .invitation__wrapper .invitation__blocks-wrapper {
  justify-content: space-between;
}

.left-background {
  position: absolute;
  max-width: 480px;
  width: calc(100% / 3);
  height: 100%;
  top: 0;
  left: 0;
  background-color: v.$color-white;
  z-index: -1;
}
