@use '../../variables.scss' as v;

.footer {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: v.$text;
  margin-top: auto;
}

.footer__wrapper {
  padding: 100px 0;
  width: 100%;
  max-width: 1170px;
  margin: 0 16px;
  display: flex;
  justify-content: space-between;
}

.footer__about-us {
  display: flex;
  flex-direction: column;
  max-width: 220px;
}

.footer__navigation {
  display: flex;
  align-items: flex-start;
}

.footer__contacts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.footer__description {
  color: white;
  font-family: 'Oswald';
  font-weight: 300;
  font-size: 17px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  line-height: 28px;
  margin: 25px 0 50px 0;
}

.footer__time-work {
  color: white;
  font-size: 15px;
  font-weight: normal;
  line-height: 30px;
}

.footer__mail {
  font-family: Oswald;
  font-size: 20px;
  font-style: normal;
  letter-spacing: 0px;
  line-height: 30px;
  text-align: right;
  text-decoration: none;
  text-transform: none;
  font-weight: 300;
}

.footer__number {
  color: white;
  font-weight: 300;
}

@media (max-width: 610px) {
  .footer__wrapper {
    flex-direction: column;
    align-items: center;
  }

  .footer__about-us {
    align-items: center;
    margin-bottom: 16px;
  }

  .footer__description {
    text-align: center;
    margin-bottom: 48px;
  }

  .footer__about-us .address {
    text-align: center;
    font-size: 13px;
    font-weight: normal;
    line-height: 20px;
    margin-bottom: 48px;
  }

  .footer__contacts {
    align-items: center;
  }

  .footer__contacts .number {
    margin-bottom: 10px;
    line-height: 32px;
  }

  .footer__time-work {
    margin-bottom: 10px;
  }

  .footer__mail {
    margin-bottom: 16px;
  }
}
