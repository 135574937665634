@use '../../variables.scss' as v;

.services-cards__title:not(.animated),
.services-cards__cards:not(.animated) {
  opacity: 0;
}

.services-cards {
  width: 100%;
  padding: 75px 16px;
}

.services-cards__wrapper {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
}

.services-cards__title {
  color: v.$title;
  font-family: 'Oswald';
  font-size: 40px;
  font-style: normal;
  letter-spacing: 0.5px;
  line-height: 50px;
  text-decoration: none;
  text-transform: none;
  margin-bottom: 15px;
  margin-top: 25px;
  font-weight: normal;
}

.services-cards__cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 0;
}

.services-cards__item {
  width: calc((100% / 3) - 20px);
}

@media (max-width: 1024px) {
  .services-cards__cards .services-cards__item {
    width: calc((100% / 2) - 20px);
  }
}

@media (max-width: 700px) {
  .services-cards__cards .services-item__image {
    position: relative;
  }

  .services-cards__cards .services-cards__item {
    width: 100%;
  }
}
