@use '../../variables.scss' as v;

.contact-us:not(.animated) {
  opacity: 0;
}

.contact-us {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-us__wrapper {
  width: 100%;
  max-width: 1170px;
  margin: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-us__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0 50px;
}

.contact-us__title {
  color: v.$title;
  font-family: 'Oswald';
  font-size: 40px;
  font-style: normal;
  letter-spacing: 0.5px;
  line-height: 50px;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  font-weight: 500;
  margin-bottom: 16px;
}

.contact-us__description {
  color: v.$text;
  font-size: 19px;
  font-style: normal;
  letter-spacing: 0px;
  line-height: 31px;
  text-align: left;
  text-decoration: none;
  text-transform: none;
  margin-bottom: 48px;
}

.contact-us__number {
  font-size: 54px;
  font-style: normal;
  letter-spacing: 0px;
  line-height: 54px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  color: v.$text;
  margin-bottom: 4px;
  margin-left: 8px;
}

.contact-us__number:hover {
  color: v.$primary;
}

@media (max-width: 444px) {
  .contact-us__title {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 8px;
    font-weight: 400;
  }

  .contact-us__number {
    font-size: 24px;
    line-height: 20px;
  }

  .contact-us__content .number svg {
    width: 30px;
    height: 30px;
  }

  .contact-us__description {
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 24px;
  }
}
