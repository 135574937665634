@font-face {
  font-family: 'OpenSans';
  src: url('../src/assets/fonts/OpenSans/OpenSans.ttf');
}

@font-face {
  font-family: 'Oswald';
  src: url('../src/assets/fonts/Oswald/Oswald.ttf');
}

html {
  scroll-behavior: auto;
  background-color: white;
}

* {
  box-sizing: border-box;
}

img {
  width: 100%;
}

p,
h1,
h2,
h3 {
  padding: 0;
  margin: 0;
}

body {
  position: relative;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  font-family: 'OpenSans', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: initial;
}

main > div {
  overflow-x: hidden;
}

button,
input {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  margin: 0;
  outline: none;
  border: 0;
}

body.burger--expanded,
body.modal--expanded {
  overflow: hidden;
  touch-action: none;
  -ms-touch-action: none;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
