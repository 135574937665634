@use '../../variables.scss' as v;

.top-block__text-wrapper:not(.animated) {
  opacity: 0;
}

.shadow {
  text-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2);
}

.top-block {
  position: relative;
  width: 100%;
  height: 578px;
  overflow: hidden;
}

.top-block__text-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-block__content {
  width: 100%;
  max-width: 1170px;
  margin: 16px;
  margin-top: 86px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.top-block__city {
  color: v.$primary;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 25px;
}

.top-block__title {
  font-family: Oswald;
  color: white;
  text-transform: uppercase;
  font-size: 90px;
  line-height: 100px;
  letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 25px;
}

.top-block__description {
  font-weight: 700;
  font-size: 24px;
  color: white;
  margin-bottom: 25px;
}

.top-block__button {
  margin: 25px 0;
}

.top-block__button:hover {
  box-shadow: 0 0 30px 0 rgba(v.$color-pink, 0.8);
}

@media (max-width: 460px) {
  .top-block {
    height: max-content;
  }

  .top-block__content > p {
    font-size: 18px;
    text-shadow: none;
  }

  .top-block__title {
    font-size: 44px;
    line-height: normal;
    margin-bottom: 16px;
    text-shadow: none;
  }

  .top-block__description {
    margin-bottom: 0;
    text-shadow: none;
  }

  .top-block__button {
    font-size: 11px;
    letter-spacing: 0px;
  }
}
