@use '../../variables.scss' as v;

.services-p {
  color: v.$text;
  font-size: 19px;
  font-style: normal;
  letter-spacing: 0px;
  line-height: 31px;
  text-align: left;
  text-decoration: none;
  text-transform: none;
  font-weight: normal;
  margin: 24px 0;
}

.services-design__cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 50px 0;
}

.services-design__item {
  justify-content: flex-start;
  width: calc((100% / 4) - 16px);
  padding: 8px;
  box-shadow: 0 10px 20px v.$shadow;
  height: 172px;
  margin-bottom: 30px;
}

.services-design__cards--row-two .services-design__item {
  width: calc(50% - 16px);
}

.services-design__item h4 {
  margin: 0;
}

.services__form {
  flex-direction: column;
}

.services__form--main {
  flex-direction: column;
  align-items: center;
}

.services__form--image {
  max-width: 500px;
  align-self: center;
}

.services__form--title,
.services__form--description {
  text-align: center;
}

.services__form--form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;
  margin: 0;
}

.services__form--form input,
.services__form--form textarea {
  background: v.$bg-secondary;
  outline-color: v.$border;
}

.services__form--form input {
  margin-bottom: 16px;
}

@media (max-width: 990px) {
  .services-design__item {
    width: calc((100% / 2) - 16px);
  }
}

@media (max-width: 860px) {
  .order__categories > div {
    width: calc(100% / 3);
  }

  .order__categories > div:nth-last-child(4) {
    border-left-color: v.$border;
    border-top-color: transparent;
  }

  .order__categories > div:nth-last-child(3) {
    border-left-color: transparent;
  }

  .order__categories > div:last-child {
    border-left-color: v.$border;
  }
}

@media (max-width: 700px) {
  .services-design .photo-gallery .photo-gallery__photo {
    width: calc(100% / 2) !important;
  }

  .services-design__item {
    width: calc((100% / 2) - 16px);
    margin-bottom: 30px;
  }
}

@media (max-width: 700px) {
  .order__categories > div {
    width: calc(100% / 2);
  }

  .order__categories > div:nth-child(3) {
    border-left-color: v.$border;
    border-top-color: transparent;
  }

  .order__categories > div:nth-child(4) {
    border-left-color: transparent;
  }

  .order__categories > div:nth-last-child(3) {
    border-left-color: v.$border;
  }
}

@media (max-width: 620px) {
  .services-design__cards--row-two .services-design__item {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .services-design .photo-gallery .photo-gallery__photo {
    width: 100% !important;
  }
}

@media (max-width: 520px) {
  .order__categories > div {
    width: 100%;
  }

  .order__categories > div:nth-child(2) {
    border-left-color: v.$border;
    border-top-color: transparent;
  }

  .order__categories > div:nth-child(4) {
    border-left-color: v.$border;
  }

  .order__categories > div:nth-last-child(2) {
    border-left-color: v.$border;
  }

  .services-design__item {
    width: 100%;
  }
}
