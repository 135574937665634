.bg-video {
  bottom: 0;
  border-radius: inherit;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -2;
}

.bg-video__video {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: lightblue;
  height: calc(100vw * 0.5625);
  left: calc(0px - ((100vw - 100%) / 2));
  position: absolute;
  top: calc(50% - ((100vw * 0.5625) / 2));
  width: 100vw;
}

.show-content {
  animation: show-content 0.8s linear;
}

@keyframes show-content {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: 1096px) {
  .bg-video__video {
    left: 0;
    min-height: 100%;
    top: 0;
    transform: translateX(-50%) translateX(50vw);
    width: calc(200vh / 0.5624);
  }
}
