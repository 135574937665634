@use '../../variables.scss' as v;

.scroll-top-button-container {
  position: fixed;
  z-index: 9998;
  width: 50px;
  height: 50px;
  transition: transform 0.3s ease-in;
}

.scroll-top-button__button {
  background-color: transparent;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  transition: box-shadow 0.1s linear;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.scroll-top-button__button svg {
  border-radius: 10px;
}

.scroll-top-button__button svg g {
  transition: fill 0.1s linear;
}

.scroll-top-button__button:hover {
  box-shadow: 0 5px 10px 0 rgba(v.$accent, 0.5);
}

.scroll-top-button__button:hover svg g {
  fill: v.$accent;
}
